import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from 'components/atoms';
import Suspense from 'services/Suspense';
import useScreenType from 'hooks/useScreenType';
import { HeaderWrapper } from './Styles';

interface IHeader {
  title?: string | ReactNode;
  handleBack?: () => void | any;
  children?: any;
  innerClassName?: string;
  headerClassName?: string;
}

const Header = ({ title, handleBack, children, innerClassName, headerClassName }: IHeader) => {
  const { isLaptop, isTablet, isMobile } = useScreenType();
  const location = useLocation();
  const hasSettingRoute = location.pathname.split('/').includes('settings');
  const hasDashboardRoute = location.pathname.split('/').includes('dashborad');
  const hasBuyNumberRoute = location.pathname.split('/settings').includes('/numbers/buy');
  const hasSettingsWithoutBuyRoute = hasSettingRoute && !hasBuyNumberRoute;

  return (
    <HeaderWrapper
      className={`${
        isTablet || isMobile ? 'fixed' : 'sticky'
      } shadow-bottom px-4 lg:px-6  w-full bg-white z-10`}
    >
      <div
        data-cy='bm-title-wrap'
        className={`${
          hasDashboardRoute || hasSettingsWithoutBuyRoute ? 'has-spacing' : 'has-nospacing'
        } header-title max-w-800 flex items-center py-3 flex-wrap gap-2 min-h-56 lg:min-h-64 ${
          innerClassName ?? 'justify-between'
        }`}
      >
        {handleBack ? (
          <button className='mr-2.5 flex items-center' onClick={handleBack} type='button'>
            <Icon name='arrow-left' className='mr-2.5 cursor-pointer icon-arrow' />
            <h6
              data-cy='bm-title'
              className={`text-gray-600 text-sm  md:block hidden  lg:text-lg font-bold leading-6.25 truncate ${
                headerClassName ?? 'max-w-260'
              }`}
            >
              {title}
            </h6>
          </button>
        ) : (
          <Suspense>
            <div className='flex items-center'>
              {(hasSettingRoute || hasDashboardRoute) && (isLaptop || isTablet || isMobile) ? (
                <>
                  <div>
                    <div className='text-gray-500 text-xs lg:text-sm'>Settings</div>
                    <h6
                      data-cy='bm-title'
                      className={`text-gray-600 text-sm lg:text-lg font-bold leading-4 lg:leading-6.25 truncate ${
                        headerClassName ?? 'max-w-260'
                      }`}
                    >
                      {title}
                    </h6>
                  </div>
                </>
              ) : (
                <h6
                  data-cy='bm-title'
                  className={`text-gray-600 text-lg font-bold leading-6.25 truncate ${
                    headerClassName ?? 'max-w-260'
                  }`}
                >
                  {title}
                </h6>
              )}
            </div>
          </Suspense>
        )}
        {children}
      </div>
    </HeaderWrapper>
  );
};

export default Header;
