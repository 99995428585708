import React, { useContext, useEffect, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import { queryParamVars, useQVars } from 'hooks/useContactVars';
import useDebounce from 'hooks/useDebounce';
import { SearchInput } from 'components/atoms';
import { HandlersContext } from '../list/HandlersContext';

export function Search() {
  const WAIT_TIME_IN_MS = 500;
  const { t } = useTranslation();
  const { setSearch } = useQVars(queryParamVars);
  const { searchedQuery, setSearchedQuery } = useContext<any>(HandlersContext);
  const debouncedQuery = useDebounce<string>(searchedQuery, WAIT_TIME_IN_MS);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedQuery?.(e.target.value);
  };

  useEffect(() => {
    if (debouncedQuery) {
      setSearch({
        columns: ['name', 'email', 'company', 'address'],
        value: debouncedQuery,
      });
      return;
    }
    setSearch(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  return (
    <>
      <SearchInput
        size='large'
        placeholder={t('searchContacts.placeholder', 'Search contacts')}
        prefix={<RiSearchLine />}
        onChange={handleSearch}
        className='contact-list-search'
      />
    </>
  );
}
