import { Button } from 'components/atoms';
import Header from 'components/molecules/header';
import { ReactNode } from 'react';

interface IEmptyScreen {
  headerTitle?: string | ReactNode;
  vector?: ReactNode;
  contentTitle: string | ReactNode;
  contentDescription?: string | ReactNode;
  handleBack?: () => void | any;
  actionHandler?: () => void | any;
  buttonText?: string | ReactNode;
}

const EmptyScreen = ({
  headerTitle,
  vector,
  contentTitle,
  contentDescription,
  handleBack,
  actionHandler,
  buttonText,
}: IEmptyScreen) => {
  return (
    <div className='h-full flex flex-col'>
      {headerTitle && <Header title={headerTitle} handleBack={handleBack} />}
      <div className='h-full flex flex-col justify-center items-center p-6'>
        {vector}
        <h5 className='mt-8 font-bold text-2xl leading-8.5 text-gray-700'>{contentTitle}</h5>
        {contentDescription && (
          <p className='mt-3 mb-10 text-base text-center text-gray max-w-400 px-5.5'>
            {contentDescription}
          </p>
        )}
        {buttonText && actionHandler && (
          <Button onClick={actionHandler} size='large' variant='success'>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyScreen;
