import { gql } from '@apollo/client';

export const CALL_STATS_SUBSCRIPTION = gql`
  subscription callStats($channels: [ShortId!]!) {
    callStats(channels: $channels) {
      event
      message {
        sid
        contact
        conversation
        agent
        workspace
        channel
        timestamp
        callTimestamp
        callStatus
        direction
        streamUrl
      }
    }
  }
`;
