import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 26px 24px;
  }
  .ant-modal-body {
    display: grid;
    justify-items: center;
  }
  h5 {
    font-family: 'Manrope';
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #3d3358;
  }
`;

export const UsageBalanceWrapper = styled.button`
  width: 100%;
  padding: 16px 22px 16px 26px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1};
  display: flex;
  gap: 8px;
  align-items: center;
  .usage-balance-text-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: ${({ theme }) => theme.colors.text.t6};
      color: var(--Grey-Default, #6e6681);
      font-family: Manrope;
      font-size: 13px;
      font-weight: 600;
      line-height: 150%; /* 19.5px */
    }
    svg {
      width: 16px;
      height: 16px;
      color: ${({ theme }) => theme.colors.text.t5};
    }
  }
  .progress-circle-anti-clock {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    line-height: 18px;
  }
`;

export const StyledPercentTag = styled.div`
  border-radius: 4px;
  background: #cce7e1;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #564d6d;
  text-align: center;
  font-family: Heebo;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px; /* 100% */
  letter-spacing: 0.24px;
`;

export const StyledPlanIntervalTag = styled.div`
  display: flex;
  padding: 4px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: #cce7e1;
  color: #564d6d;
  text-align: center;
  font-family: Heebo;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
`;

export const UsageModalContent = styled.div`
  width: 100%;
  p.into-text {
    color: ${({ theme }) => theme.colors.text.t6};
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  header {
    display: flex;
    gap: 8px;
    align-items: center;
    h3 {
      color: ${({ theme }) => theme.colors.text.t8};
      font-family: Heebo;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  section {
    display: grid;
    gap: 8px;
    h4 {
      display: flex;
      justify-content: space-between;
      color: ${({ theme }) => theme.colors.text.t8};
      font-family: Heebo;
      font-size: 15px;
      font-weight: 400;
      line-height: 150%; /* 22.5px */
      .sub-text {
        color: ${({ theme }) => theme.colors.text.t6};
        text-align: right;
        font-family: Heebo;
        font-size: 15px;
        font-weight: 400;
        line-height: 150%; /* 22.5px */
      }
    }
    .ant-progress-line {
      line-height: 5px;
    }
  }
`;
