import { useContext } from 'react';
import { CallBoxContext } from '../context/CallBoxContext';

export default function useCallBoxContext() {
  const context = useContext(CallBoxContext);
  if (!context) {
    throw new Error('useCallBoxContext must be used within a CallBoxProvider');
  }
  return context;
}
