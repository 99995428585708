import React from 'react';
import { useSubscription } from '@apollo/client';

interface SubscriptionHandlerProps {
  subscription: any;
  variables?: Record<string, any>;
  onSubscriptionData?: (arg: any) => void;
  skip?: boolean; // Optional to skip the subscription
}

const SubscriptionHandler: React.FC<SubscriptionHandlerProps> = ({
  subscription,
  variables,
  onSubscriptionData,
  skip = false,
}) => {
  useSubscription(subscription, {
    variables,
    skip, // Apollo Client's built-in skip functionality
    onSubscriptionData,
  });

  return null; // No UI rendering required
};

export default SubscriptionHandler;
