/* eslint-disable no-param-reassign */

import { cache } from 'services/apollo';

const useCacheUpdate = () => {
  const updateCacheClientInfo = (contactId: string, updateValues: Record<string, any>) => {
    cache.modify({
      id: cache.identify({ id: contactId, __typename: 'ClientInfo' }),
      fields: {
        ...Object.keys(updateValues).reduce((acc: any, key) => {
          acc[key] = () => updateValues[key]; // Apply the dynamic updates
          return acc;
        }, {}),
      },
    });
  };

  return {
    updateCacheClientInfo,
  };
};

export default useCacheUpdate;
