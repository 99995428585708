import { ApolloClient, from, split, ApolloLink } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { datadogLogs } from '@datadog/browser-logs';
import { getMainDefinition } from '@apollo/client/utilities';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { createUploadLink } from 'apollo-upload-client';
import SecureLS from 'secure-ls';

import { geUserTimeZone } from 'components/utils/datetime';
import { encodeValueFernet } from 'services/datadog/helper';
import {
  API_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  ANALYTICS_ENDPOINT,
  ANALYTICS_SUB_ENDPOINT,
  BULK_SMS_API_ENDPOINT,
  AUTOMATION_API_ENDPINT,
  SALES_DIALER_API_ENDPINT,
  SALES_DIALER_SUB_ENDPOINT,
  CRM_URI,
} from 'constants/endpoint';

import tokenRefresh from './tokenRefresh';
import useErrorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';
import { customFetch } from './helpers';
import { salesDialerSub, dashboardAnalyticsSub } from './constants';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const debug = process.env.REACT_APP_DEBUG === 'true';

const uploadLink = createUploadLink({
  uri: API_ENDPOINT ?? Cypress.env('api_endpoint'),
  fetch: customFetch as any,
});

// will move to utils
const getToken = () => {
  let user: any = {};
  try {
    const data = ls.get('_tokens') ?? JSON.stringify('');
    user = data && JSON.parse(data);
  } catch (e) {
    console.warn(e);
  }
  const token = user?.accessToken || '';
  return token;
};

const getConnectionParams = () => {
  const token = getToken();
  if (!token || token === '') {
    throw new Error('Access token is missing.');
  }
  return {
    accessToken: getToken(),
  };
};
// eslint-disable-next-line import/no-mutable-exports
export let activeSocket: { send: (arg0: string) => void };

const getUserInfoLocalstorage = () => {
  const user = ls.get('user');
  const userObj = user ? JSON.parse(user) : {};
  const lsEmail = userObj?.details?.userProfile?.email;
  const lsToken = userObj?.token ? encodeValueFernet(userObj?.token) : undefined;
  return { lsEmail, lsToken };
};

const logErrorDatadog = (errMessage: string, error: any) => {
  const { lsEmail, lsToken } = getUserInfoLocalstorage();
  datadogLogs.logger.error(errMessage, {
    datadogError: {
      error,
      url: error?.target?.url,
      errorMessage: 'ws connection failed',
    },
    context: 'websocket',
    lsEmail,
    lsToken,
  });
};

const logWsConnectedInfoDatadog = (wsUrl: string) => {
  const { lsEmail, lsToken } = getUserInfoLocalstorage();
  datadogLogs.logger.info(`Websocket connected ${wsUrl}`, {
    context: 'websocket',
    lsEmail,
    lsToken,
  });
};

export const wsLink: any = new GraphQLWsLink(
  createClient({
    url: `${SUBSCRIPTION_ENDPOINT}/`,
    lazy: true,
    retryWait: retries => {
      const delay = Math.min(1000 * 2 ** retries, 30000); // Exponential backoff (max 30s)
      datadogLogs.logger.info(
        `Retrying WebSocket connection in ${delay / 1000}s... ${SUBSCRIPTION_ENDPOINT}`,
      );
      return new Promise(resolve => setTimeout(resolve, delay));
    },
    shouldRetry: () => true,
    connectionParams: () => getConnectionParams(),
    on: {
      connected: () => {
        if (debug) console.log(`${SUBSCRIPTION_ENDPOINT} - ws connected`);
        logWsConnectedInfoDatadog(`${SUBSCRIPTION_ENDPOINT}`);
      },
      closed: () => debug && console.log(`${SUBSCRIPTION_ENDPOINT} - ws closed`),
      error: error => {
        if (debug) console.error(`${SUBSCRIPTION_ENDPOINT} - ws error`);
        logErrorDatadog(`WebSocket Error ${SUBSCRIPTION_ENDPOINT} `, error);
      },
    },
  }),
);

export const salesdialerWsLink: any = new GraphQLWsLink(
  createClient({
    url: `${SALES_DIALER_SUB_ENDPOINT}/`,
    lazy: true,
    retryWait: retries => {
      const delay = Math.min(1000 * 2 ** retries, 30000); // Exponential backoff (max 30s)
      datadogLogs.logger.info(
        `Retrying WebSocket connection in ${delay / 1000}s... ${SALES_DIALER_SUB_ENDPOINT}`,
      );
      return new Promise(resolve => setTimeout(resolve, delay));
    },
    shouldRetry: () => true,
    connectionParams: () => getConnectionParams(),
    on: {
      connected: () => {
        if (debug) console.log(`${SALES_DIALER_SUB_ENDPOINT} - ws connected`);
        logWsConnectedInfoDatadog(`${SALES_DIALER_SUB_ENDPOINT}`);
      },
      closed: () => debug && console.log(`${SALES_DIALER_SUB_ENDPOINT} - ws closed`),
      error: error => {
        if (debug) console.error(`${SALES_DIALER_SUB_ENDPOINT} - ws error`);
        logErrorDatadog(`WebSocket Error ${SALES_DIALER_SUB_ENDPOINT} `, error);
      },
    },
  }),
);

export const dashWsLink: any = new GraphQLWsLink(
  createClient({
    url: `${ANALYTICS_SUB_ENDPOINT}/`,
    lazy: true,
    retryWait: retries => {
      const delay = Math.min(1000 * 2 ** retries, 30000); // Exponential backoff (max 30s)
      datadogLogs.logger.info(
        `Retrying WebSocket connection in ${delay / 1000}s... ${ANALYTICS_SUB_ENDPOINT}`,
      );
      return new Promise(resolve => setTimeout(resolve, delay));
    },
    shouldRetry: () => true,
    connectionParams: () => getConnectionParams(),
    on: {
      connected: () => {
        if (debug) console.log(`${ANALYTICS_SUB_ENDPOINT} - ws connected`);
        logWsConnectedInfoDatadog(`${ANALYTICS_SUB_ENDPOINT}`);
      },
      closed: () => debug && console.log(`${ANALYTICS_SUB_ENDPOINT} - ws closed`),
      error: error => {
        if (debug) console.error(`${ANALYTICS_SUB_ENDPOINT} - ws error`);
        logErrorDatadog(`WebSocket Error ${ANALYTICS_SUB_ENDPOINT} `, error);
      },
    },
  }),
);

export const resetWSLink = (callback?: () => void) => {
  if (debug) console.log('reset ws link');
  // Properly close WebSocket
  wsLink?.client?.dispose();
  dashWsLink?.client?.dispose();
  salesdialerWsLink?.client?.dispose();
  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 1000);
};

// Create a custom WebSocket link manager
const createCustomWsLink = (operation: any, forward: any) => {
  const definition = getMainDefinition(operation.query);
  if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
    // Define your custom logic to route subscriptions to different WebSocket links
    if (salesDialerSub.includes(operation?.operationName)) {
      return salesdialerWsLink.request(operation) || forward(operation);
    }
    if (dashboardAnalyticsSub.includes(operation?.operationName)) {
      return dashWsLink.request(operation) || forward(operation);
    }
    return wsLink.request(operation) || forward(operation);
  }
  return forward(operation);
};

// Combine the custom WebSocket link with the HTTP link
const customLink = new ApolloLink((operation, forward) => {
  return createCustomWsLink(operation, forward);
});

interface Definition {
  kind: string;
  operation?: string;
}

function useApolloService() {
  // this is basically telling when to use http link and ws link
  const defaultLink = split(
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    // wsLink,
    customLink,
    // eslint-disable-next-line prettier/prettier
    authLink.concat((uploadLink as unknown) as ApolloLink),
  );

  const multiClientLink = new MultiAPILink({
    endpoints: {
      dash: ANALYTICS_ENDPOINT as string,
      bulkSms: BULK_SMS_API_ENDPOINT as string,
      automation: AUTOMATION_API_ENDPINT as string,
      salesDialer: SALES_DIALER_API_ENDPINT as string,
      crmIntegrateEndPoint: CRM_URI as string,
      crmConnectBitrixUrl: `${CRM_URI}/bitrix24` as string,
      crmConnectZendeskUrl: `${CRM_URI}/zendesk` as string,
    },
    createHttpLink: () => createUploadLink() as any,
    // createWsLink: () => dashWsLink, // handled in createCustomWsLink
    httpSuffix: '/graphql/',
    wsSuffix: '/graphql/ws',
    getContext: (endpoint: string) => {
      return {
        headers: {
          Authorization: `JWT ${getToken()}`,
          tz: geUserTimeZone(),
        },
      };
    },
  });

  const { errorLink } = useErrorLink();

  const client = new ApolloClient({
    cache: cache as any,
    link: from([tokenRefresh, errorLink, multiClientLink, defaultLink]),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy(lastFetchPolicy) {
          if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
            return 'cache-first';
          }
          return lastFetchPolicy;
        },
      },
    },
  });

  return { client };
}

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

export default useApolloService;

export { cache };
