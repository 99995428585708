export const ENABLE_ADD_WORKSPACE_FEATURE =
  process.env.REACT_APP_ENABLE_ADD_WORKSPACE_FEATURE === 'true';
export const ENABLE_DIALER_V3 = process.env.REACT_APP_ENABLE_DIALER_V3 === 'true';
export const ENABLE_NON_VOIP_FEATURE = process.env.REACT_APP_NON_VOIP === 'true';
export const ENABLE_WORKFLOW_SMS_OPTION =
  process.env.REACT_APP_ENABLE_WORKFLOW_SMS_OPTION === 'true';
export const ENABLES_FREE_USAGE_BALANCE = process.env.REACT_APP_SHOW_FREE_USAGE_BALANCE === 'true';

export const ENABLE_RECORDING_TRANSCRIPT =
  process.env.REACT_APP_SHOW_RECORDING_TRANSCRIPT === 'true';
export const SMS_AND_CONNECTED_NUM_ENABLED_CRM_LIST =
  process.env.REACT_APP_SMS_AND_CONNECTED_NUM_ENABLED_CRM_LIST;
export const NEW_ARCHITECTURE_CRMS = process.env.REACT_APP_NEW_ARCH_CRM_LIST;
