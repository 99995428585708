import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';
import {
  WORKSPACE_NODE,
  MEMBER_NODE,
  INVITE_NODE,
  FREE_USAGE_BALANCE_FRAGMENT,
  PLAN_OVERVIEW_NODE,
} from './fragments';

export const WORKSPACE = gql`
  query workspace {
    workspace {
      status
      error {
        ...Error
      }
      data {
        ...WorkspaceFields
      }
    }
  }
  ${WORKSPACE_NODE}
  ${ERROR_FRAGMENT}
`;

export const WORKSPACES = gql`
  query workspaces {
    workspaces {
      status
      error {
        ...Error
      }
      data {
        id
        title
        kycVerified
        memberId
        photo
        status
        order
        reviewStatus
        twilioStatus
        plan {
          ...PlanOverviewField
        }
        numberSubscription {
          subscriptionStatus
          startedAt
          endedAt
          autoRenew
        }
        nonVoipSubscriptionStatus {
          subscriptionStatus
        }
        createdOn
      }
    }
  }
  ${PLAN_OVERVIEW_NODE}
  ${ERROR_FRAGMENT}
`;

export const INVITE_MEMBER_EMAIL_EXISTS = gql`
  query inviteMemberEmailExists($email: String!) {
    inviteMemberEmailExists(email: $email) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const INVITE_DETAILS = gql`
  query inviteDetail($invitationCode: String!) {
    inviteDetail(invitationCode: $invitationCode) {
      status
      data {
        email
        workspace {
          id
          title
          image
          role
        }
        invitationType
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const WORKSPACE_MEMBERS = gql`
  query allWorkspaceMembers($pageParams: ConnectionInput!) {
    allWorkspaceMembers(pageParams: $pageParams) {
      data {
        edges {
          cursor
          node {
            ...MemberNodeFields
            memberCrmIntegration {
              id
              crmId
              crmUser
              crmPlatform
              crmName
              crmLogo
              crmDescription
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      error {
        ...Error
      }
      status
    }
  }
  ${MEMBER_NODE}
  ${PAGE_INFO}
  ${ERROR_FRAGMENT}
`;

export const WORKSPACE_MEMBERS_V3 = gql`
  query allWorkspaceMembers($pageParams: ConnectionInput!) @api(name: "crmIntegrateEndPoint") {
    allWorkspaceMembers(pageParams: $pageParams) {
      data {
        edges {
          cursor
          node {
            ...MemberNodeFields
            memberCrmIntegration {
              id
              crmId
              crmUser
              crmPlatform
              crmName
              crmLogo
              crmDescription
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      error {
        ...Error
      }
      status
    }
  }
  ${MEMBER_NODE}
  ${PAGE_INFO}
  ${ERROR_FRAGMENT}
`;

export const ALL_INVITES = gql`
  query allInvites($pageParams: ConnectionInput) {
    allInvites(pageParams: $pageParams) {
      data {
        edges {
          cursor
          node {
            ...InviteNodeFields
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      error {
        ...Error
      }
      status
    }
  }
  ${INVITE_NODE}
  ${PAGE_INFO}
  ${ERROR_FRAGMENT}
`;

export const TIMEZONES_LIST = gql`
  query allTimeZones {
    allTimeZones {
      status
      error {
        ...Error
      }
      data {
        value
        abbr
        offset
        isdst
        text
        utc
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const GET_WORKSPACE_CREDIT = gql`
  query getWorkspaceCredit {
    getWorkspaceCredit {
      status
      error {
        ...Error
      }
      data {
        currentCredit
      }
    }
  }

  ${ERROR_FRAGMENT}
`;

export const GET_FREE_USAGE_BALANCE = gql`
  query freeUsageBalance {
    workspace {
      status
      error {
        ...Error
      }
      data {
        freeUsageBalance {
          ...FreeUsageBalanceFields
        }
      }
    }
  }
  ${FREE_USAGE_BALANCE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;
