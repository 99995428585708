import { createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSettingQuery } from '../../settings/my-number/hooks/useSettingQuery';

interface ICallboxContext {
  configs: any;
  agents?: [];
}

export const CallBoxContext = createContext<ICallboxContext>({} as any);

export const CallBoxProvider = ({ children }: any) => {
  const { channelId } = useParams<Record<string, string | undefined>>();
  const { configs } = useSettingQuery({ channelId });
  const { agents } = configs || {};

  const contextValue = useMemo(
    () => ({
      configs,
      agents, // agents to whom the number in shared
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configs],
  );

  return <CallBoxContext.Provider value={contextValue}>{children}</CallBoxContext.Provider>;
};
