import { produce } from 'immer';
import iEmpty from 'lodash.isempty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { parsePhoneNumber, isValidPhoneNumber } from 'components/utils/phone-lib';
import { ToastMessage } from 'components/atoms';
import { ALL_TAGS } from 'graphql/client';
import { Tag } from 'generated/graphql';
import useContactDetails from './useContactDetails';
import useTagOperations from './useTagOperations';

import { createOption } from '../utils';

const useContactEditHandlers = (props: any) => {
  const { data, methods } = props || {};
  const notesFieldRef = useRef<any>();
  const { t } = useTranslation();
  const {
    setTagChange,
    setTagFieldValue,
    setIsTagFieldLoading,
    setShowTagColorOptions,
    setNewlyCreatedTagValue,
    addTag,
    tagChange,
    isTagFieldLoading,
    tagSelectRef,
    tagFieldOptions,
    showTagColorOptions,
    tagFieldValue,
    newlyCreatedTagValue,
  } = useTagOperations();
  const { setEditableField, handleInputSave, editableField } = useContactDetails({
    data,
  });

  const handleEnterSubmit = (e: any) => {
    e.preventDefault();
    setEditableField('');
    let { value } = e.target;
    if (!iEmpty(methods.formState?.errors)) {
      ToastMessage({ content: t('toast.validationError', 'Validation Error'), type: 'danger' });
      return;
    }

    // if existing value unchanged early return
    if (data[e.target.name] === value) {
      return;
    }

    if (e.target.name === 'number' && e.target.value) {
      const parsedPhoneNumber = parsePhoneNumber(e.target.value);
      const isValid = isValidPhoneNumber(e.target.value);
      if (!isValid) {
        ToastMessage({ content: t('toast.invalidNumber', 'Invalid Phone Number'), type: 'danger' });
        return;
      }
      value = parsedPhoneNumber?.number;
    }
    handleInputSave(e.target.name, value);
  };

  const handleSelectChange = (field: string, fieldValue: any) => {
    let value = fieldValue;
    if (field === 'visibility' && value) {
      value = fieldValue === 'Public';
    }
    handleInputSave(field, value);
  };

  const handleEditableChange = (field: string) => (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEditableField(field);
  };

  /* TAGS handlers */
  const handleTagChange = (newValue: any, actionMeta: any) => {
    setTagChange(true);
    setTagFieldValue(newValue);
  };
  const handleTagCreate = (inputValue: any) => {
    if (!inputValue.trim()) {
      ToastMessage({
        content: t('toast.tagCreateError', 'Cannot create empty tag.'),
        type: 'danger',
      });
      return;
    }
    if (inputValue.length < 2) {
      ToastMessage({
        content: t('toast.minCharLimitTag', 'Should contain atleast 2 characters.'),
        type: 'danger',
      });
      return;
    }
    if (inputValue.length > 46) {
      ToastMessage({
        content: t('toast.exceedCharLimitTag', "Shouldn't exceed 46 characters."),
        type: 'danger',
      });
      return;
    }
    setIsTagFieldLoading(true);
    setShowTagColorOptions(true);
    setNewlyCreatedTagValue(inputValue);
  };
  const handleColorPicking = async (tag: Tag) => {
    const newTagId = (Math.random() * 100).toString();
    const newOption = createOption(
      newTagId,
      newlyCreatedTagValue,
      tag.colorCode as string,
      tag.backgroundColorCode as string,
    );
    setIsTagFieldLoading(false);
    setShowTagColorOptions(false);
    if (newOption) {
      const response = await addTag({
        variables: {
          data: {
            title: newOption.label,
            colorCode: newOption.colorCode,
            backgroundColorCode: newOption.backgroundColorCode,
          },
        },
        update(cache, { data: { addTag: tagAddResult } }) {
          const cachedTags: any = cache.readQuery({ query: ALL_TAGS });
          if (tagAddResult?.data) {
            const updatedTags = produce(cachedTags, (draft: any) => {
              if (draft?.allTags?.data) {
                draft.allTags.data.push({
                  ...tagAddResult.data,
                  __typename: 'TagsPayload',
                  count: 0,
                });
              }
            });
            cache.writeQuery({
              query: ALL_TAGS,
              data: updatedTags,
            });
          }
        },
      });
      if (response?.data?.addTag?.error?.code === 409) {
        ToastMessage({
          content: `${t('toast.tagExist', 'Tag name already exist.')}`,
          type: 'danger',
        });
      }
      if (response?.data?.addTag?.status === 201) {
        const newTagOptions = {
          ...response?.data?.addTag?.data,
          label: response?.data?.addTag?.data?.title,
          value: response?.data?.addTag?.data?.id,
        };
        const updatedTagList = [...tagFieldValue, newTagOptions];
        setTagFieldValue(updatedTagList);
        const tagIds = updatedTagList.map(tagField => tagField.id);
        if (tagIds) {
          handleInputSave('tags', tagIds);
        }
      }
    }
  };

  const handleOnTagBlur = () => {
    const tagIds = tagFieldValue.map((tag: Tag) => tag.id);
    if (tagIds && tagChange) {
      handleInputSave('tags', tagIds);
    }
  };

  const resetTagsField = () => {
    setTagFieldValue([]);
    setIsTagFieldLoading(false);
    setShowTagColorOptions(false);
  };

  const handleSaveContactName = (value: string) => {
    if (data?.name === value) {
      return;
    }
    handleInputSave('name', value);
  };

  return {
    handleEnterSubmit,
    handleSelectChange,
    handleEditableChange,
    handleTagChange,
    handleTagCreate,
    handleOnTagBlur,
    handleColorPicking,
    handleSaveContactName,
    isTagFieldLoading,
    tagFieldOptions,
    showTagColorOptions,
    editableField,
    setTagFieldValue,
    tagFieldValue,
    tagSelectRef,
    notesFieldRef,
    resetTagsField,
  };
};

export default useContactEditHandlers;
