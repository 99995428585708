import { gql } from '@apollo/client';

export const CONVERSATION_CONTENT = gql`
  fragment ContentFields on ConversationContent {
    body
    transferedAudio
    duration
    callDuration
    callTime
    transcript
  }
`;

export const AGENT_INFO = gql`
  fragment AgentInfoFields on AgentInfo {
    agentId
    firstname
    lastname
    profilePicture
  }
`;

export const CLIENT_INFO = gql`
  fragment ClientInfoFields on ClientInfo {
    id
    number
    name
    country
    createdBy
    profilePicture
    blocked
  }
`;

export const CLIENT_DND_INFO = gql`
  fragment ClientDndInfoFields on ClientInfo {
    dndEnabled
    dndDuration
    dndEndtime
    dndInfo {
      dndDuration
      dndEnabled
      dndEndtime
    }
  }
`;

export const PERSONALIZED_INFO = gql`
  fragment PersonalizedInfoFields on PersonalizedInfo {
    pinned
    seen
    favourite
    dndMissed
    reject
  }
`;

export const COMMENT_THREAD = gql`
  fragment CommentThreadFields on Comment {
    id
    agentId
    comment
    status
    createdAt
    updatedAt
  }
`;

export const MEDIAS = gql`
  fragment MediaFields on Media {
    url
    size
    type
    name
  }
`;

export const CHANNEL_INFO = gql`
  fragment ChannelInfoField on ChannelInfoo {
    channelId
  }
`;

export const CONVERSATION_NODE = gql`
  fragment ConversationFields on ConversationNode {
    id
    direction
    status
    clientNumber
    clientCountry
    clientUnseenMsgCount
    conversationStatus
    conversationType
    contactClosed
    contactPinned
    transcriptionStatus
    transcriptionsRequestedBy
    createdAt
    modifiedAt
    sms
    errorMessage
    smsForwardedNumber
    content {
      ...ContentFields
    }
    clientInfo {
      ...ClientInfoFields
    }
    agentInfo {
      ...AgentInfoFields
    }
    personalizedInfo {
      ...PersonalizedInfoFields
    }
    medias {
      ...MediaFields
    }
    commentThread {
      ...CommentThreadFields
    }
    channelInfo {
      ...ChannelInfoField
    }
  }
  ${CONVERSATION_CONTENT}
  ${AGENT_INFO}
  ${CLIENT_INFO}
  ${PERSONALIZED_INFO}
  ${MEDIAS}
  ${COMMENT_THREAD}
  ${CHANNEL_INFO}
`;

// USING MULTIPLE FRAGMENTS GAVE ISSUE FOR WRITEFRAGMENT FUNCTION CALL FOR CACHE UPDATE OF RECENT CONVERSATION DATA
// export const RECENT_CONVERSATION_NODE = gql`
//   fragment RecentConversationFields on RecentConversationNode {
//     id
//     direction
//     status
//     clientNumber
//     clientCountry
//     clientUnseenMsgCount
//     conversationStatus
//     conversationType
//     contactClosed
//     contactPinned
//     createdAt
//     sms
//     content {
//       ...ContentFields
//     }
//     clientInfo {
//       ...ClientInfoFields
//       ...ClientDndInfoFields
//     }
//     agentInfo {
//       ...AgentInfoFields
//     }
//     medias {
//       ...MediaFields
//     }
//   }
//   ${CONVERSATION_CONTENT}
//   ${AGENT_INFO}
//   ${CLIENT_INFO}
//   ${CLIENT_DND_INFO}
//   ${MEDIAS}
// `;

export const RECENT_CONVERSATION_NODE = gql`
  fragment RecentConversationFields on RecentConversationNode {
    id
    direction
    status
    clientNumber
    clientCountry
    clientUnseenMsgCount
    conversationStatus
    conversationType
    contactClosed
    contactPinned
    createdAt
    sms
    content {
      body
      transferedAudio
      duration
      callDuration
      callTime
      transcript
    }
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
      blocked
      dndEnabled
      dndDuration
      dndEndtime
      dndInfo {
        dndDuration
        dndEnabled
        dndEndtime
      }
    }
    agentInfo {
      agentId
      firstname
      lastname
      profilePicture
    }
    medias {
      url
      size
      type
      name
    }
  }
`;

// USING MULTIPLE FRAGMENTS GAVE ISSUE FOR WRITEFRAGMENT FUNCTION CALL FOR CACHE UPDATE OF CONTACT PINNED RECENT CONVERSATION DATA
// export const CONTACT_PINNED_CONVERSATION_NODE = gql`
//   fragment PinnedConversationFields on PinnedConversationNode {
//     id
//     direction
//     status
//     clientNumber
//     clientCountry
//     clientUnseenMsgCount
//     conversationStatus
//     conversationType
//     contactClosed
//     contactPinned
//     createdAt
//     sms
//     content {
//       ...ContentFields
//     }
//     clientInfo {
//       ...ClientInfoFields
//       ...ClientDndInfoFields
//     }
//     agentInfo {
//       ...AgentInfoFields
//     }
//     medias {
//       ...MediaFields
//     }
//   }
//   ${CONVERSATION_CONTENT}
//   ${AGENT_INFO}
//   ${CLIENT_INFO}
//   ${CLIENT_DND_INFO}
//   ${MEDIAS}
// `;

export const CONTACT_PINNED_CONVERSATION_NODE = gql`
  fragment PinnedConversationFields on PinnedConversationNode {
    id
    direction
    status
    clientNumber
    clientCountry
    clientUnseenMsgCount
    conversationStatus
    conversationType
    contactClosed
    contactPinned
    createdAt
    sms
    content {
      body
      transferedAudio
      duration
      callDuration
      callTime
      transcript
    }
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
      blocked
      dndEnabled
      dndDuration
      dndEndtime
      dndInfo {
        dndDuration
        dndEnabled
        dndEndtime
      }
    }
    agentInfo {
      agentId
      firstname
      lastname
      profilePicture
    }
    medias {
      url
      size
      type
      name
    }
  }
`;

export const PINNED_CONVERSATION_NODE = gql`
  fragment PinnedConversationFields on ConversationNode {
    id
    direction
    clientNumber
    conversationStatus
    conversationType
    transcriptionStatus
    createdAt
    sms
    content {
      ...ContentFields
    }
    clientInfo {
      ...ClientInfoFields
    }
    agentInfo {
      ...AgentInfoFields
    }
    medias {
      ...MediaFields
    }
  }
  ${CONVERSATION_CONTENT}
  ${AGENT_INFO}
  ${CLIENT_INFO}
  ${MEDIAS}
`;

export const AGENT_NODE = gql`
  fragment AgentFields on Agent {
    id
    firstname
    email
    role
    lastname
    photo
  }
`;
export const NUMBER_NODE = gql`
  fragment NumberFields on NumbersNode {
    id
    name
    number
    agents {
      id
      firstname
      lastname
      photo
    }
    callStrategy
    numberCheckoutPrice {
      setUpFee
      monthlyFee
      basePrice
    }
  }
`;
export const PENDING_NUMBER_NODE = gql`
  fragment PendingNumberResponse on PendingNumber {
    name
    number
    country
    setUpFee
    monthlyFee
    purchaseStatus
    purchase_status
    addressRequirement
    regulationSid
    call
    sms
    mms
    type
  }
`;
export const CHANNEL_NODE = gql`
  fragment ChannelFields on Channel {
    id
    countryLogo
    country
    countryCode
    number
    name
    dndEndtime
    dndEnabled
    dndRemainingTime
    dndOn
    dndDuration
    unseenMessageCount
    sms
    call
    mms
    numberCheckoutPrice {
      basePrice
    }
  }
`;
export const NONVOIP_CHANNEL_NODE = gql`
  fragment ChannelFields on Channel {
    id
    countryLogo
    country
    countryCode
    number
    name
    dndEndtime
    dndEnabled
    dndRemainingTime
    dndOn
    dndDuration
    unseenMessageCount
    sms
    call
    mms
    phoneNumberType
    nonVoipSubscription {
      status
    }
    numberCheckoutPrice {
      basePrice
    }
  }
`;

export const NUMBER_ABILITIES_NODE = gql`
  fragment NumberAbilitiesFields on NumberAbilities {
    call
    sms
    mms
  }
`;

export const CHECKOUT_NUMBER = gql`
  fragment CheckoutNumberFields on CheckoutNumber {
    name
    number
    country
    address
    setUpFee
    monthlyFee
    sms
    purchaseStatus
    mms
    call
    type
    status
  }
`;

export const NUMBER_CHECKOUT_PRICE = gql`
  fragment NumberCheckoutPriceFields on NumberCheckoutPrice {
    setUpFee
    monthlyFee
    basePrice
  }
`;
