import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import SecureLS from 'secure-ls';
import { datadogLogs } from '@datadog/browser-logs';

import { encodeValueFernet, encryptKeysDatadog } from 'services/datadog/helper';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;

export const handleLogoutWithoutHook = () => {
  // Logout without hook
  ls.clear();
  sessionStorage.clear();
  Cookies.remove(`${COOKIE_PREFIX}_kc_auth_tk`);
  console.log('log out reload');
  // do other stuff required when logout
  // eslint-disable-next-line no-restricted-globals
  location.reload();
  // location.reload() after token removed affects user redirect
};

export default function useErrorLink() {
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: any) => {
    const { operationName, variables } = operation || {};
    const authorizationToken = operation.getContext().headers?.authorization;
    const user = ls.get('user');
    const userObj = user ? JSON.parse(user) : {};
    const lsEmail = userObj?.details?.userProfile?.email;
    const lsToken = userObj?.token
      ? encryptKeysDatadog({ token: userObj?.token })?.token
      : undefined;
    if (process.env.NODE_ENV === 'production' && graphQLErrors) {
      datadogLogs.logger.error(`GraphQL Error ${operationName} `, {
        dataDogError: graphQLErrors,
        context: 'graphql error',
        requestPayload: {
          operationName,
          variables: encryptKeysDatadog(variables),
        },
        authorizationToken: encodeValueFernet(authorizationToken),
        lsEmail,
        lsToken,
      });
    }

    if (graphQLErrors?.length > 0 && graphQLErrors[0]?.extensions?.code === 'Expired') {
      const deviceId = localStorage.getItem('deviceId');
      handleLogoutWithoutHook();
      localStorage.setItem('deviceId', deviceId as string);
      datadogLogs.logger.info(`Web Token Expired  ${operationName}`, {
        dataDogError: graphQLErrors,
        context: 'token expired',
        requestPayload: {
          operationName,
          variables: encryptKeysDatadog(variables),
        },
        authorizationToken: encodeValueFernet(authorizationToken),
        lsEmail,
        lsToken,
      });
    }
    if (networkError) {
      const { status, error } = networkError?.result || {};
      // if (process.env.NODE_ENV === 'production') {
      //   logRollbarError.warn(
      //     `Network Error : ${networkError?.result?.errors?.[0]?.message}`,
      //     networkError?.result?.errors?.[0] ?? networkError,
      //   );
      // }
      if (status === 401 && error?.error_key === 'token_expired') {
        // HANDLE_LOGOUT_FOR_DUPLICATE_LOGIN
        const deviceId = localStorage.getItem('deviceId');
        handleLogoutWithoutHook();
        localStorage.setItem('deviceId', deviceId as string);
        datadogLogs.logger.info(`Web Token Expired  ${operationName}`, {
          dataDogError: graphQLErrors,
          context: 'token expired',
          requestPayload: {
            operationName,
            variables: encryptKeysDatadog(variables),
          },
          authorizationToken: encodeValueFernet(authorizationToken),
          lsEmail,
          lsToken,
        });
      }
      if (networkError?.result) console.error('[Network Error Result]', networkError?.result);
      datadogLogs.logger.error(`Network Error ${operationName} `, {
        errorMessage: networkError?.message,
        datadogError: { error: networkError, errorMessage: networkError?.message },
        context: 'network error',
        requestPayload: {
          operationName,
          variables: encryptKeysDatadog(variables),
        },
        authorizationToken: encodeValueFernet(authorizationToken),
        lsEmail,
        lsToken,
      });
    }
    return forward(operation);
  });

  return {
    errorLink,
  };
}
