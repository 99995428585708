import { gql } from '@apollo/client';

import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';
import { CHANNEL_NODE, NONVOIP_CHANNEL_NODE } from 'graphql/channel/fragments';

export const PLAN_OVERVIEW_NODE = gql`
  fragment PlanOverviewField on PlanOverviewData {
    remainingDays
    subscriptionActive
    trialPeriod
    cardInfo
    currentCredit
    startedAt
    endedAt
    planDetail {
      title
      interval
    }
    canceledBy
    paymentMethod
    customerId
    remainingGracePeriod
  }
`;

export const WORKSPACE_NODE = gql`
  fragment WorkspaceFields on Workspace {
    id
    title
    photo
    kycVerified
    channels {
      ...ChannelFields
    }
    reviewStatus
    twilioStatus
    plan {
      ...PlanOverviewField
    }
    notification
    status
    features {
      id
      title
      status
    }
  }
  ${ENABLE_NON_VOIP_FEATURE ? NONVOIP_CHANNEL_NODE : CHANNEL_NODE}
  ${PLAN_OVERVIEW_NODE}
`;

export const MEMBER_NODE = gql`
  fragment MemberNodeFields on MemberNode {
    id
    firstname
    lastname
    role
    gender
    email
    createdOn
    profilePicture
    memberRate {
      rate
    }
    numbers {
      name
      number
      country
      countryLogo
      countryCode
      settings {
        shared
      }
    }
    planRate {
      planRate
    }
    unSeenMsgCount
    online
    onCall
    last_message {
      createdOn
      message
    }
  }
`;
export const INVITE_NODE = gql`
  fragment InviteNodeFields on InviteNode {
    id
    role
    code
    email
    createdOn
    type
    status
  }
`;

export const FREE_USAGE_BALANCE_FRAGMENT = gql`
  fragment FreeUsageBalanceFields on FreeUsageBalance {
    totalInboundMinutes
    totalOutboundMinutes
    totalSmsSegments
    inboundMinutes
    outboundMinutes
    smsSegments
    endedAt
  }
`;
