import { Trans } from 'react-i18next';
import { OnSubscriptionDataOptions } from '@apollo/client';

import { UPDATED_CREDIT } from 'graphql/payments';
import SubscriptionHandler from 'services/apollo/SubscriptionHandler';

import { LowCreditButton } from './Styles';

interface IProps {
  workspaceId: string;
  credit: number | string;
}

const CreditAmount = ({ credit, workspaceId }: IProps) => {
  const onSubscriptionDataCallback = (arg: OnSubscriptionDataOptions<any>) => {
    const cache = arg?.client?.cache;
    if (arg?.subscriptionData?.data?.updateCredit) {
      cache.modify({
        fields: {
          workspace: () => {},
        },
      });
    }
  };
  const isCreditLessThanPointFive = parseFloat(credit.toString()) <= 0.5;

  return (
    <>
      {workspaceId && (
        <SubscriptionHandler
          subscription={UPDATED_CREDIT}
          variables={{
            workspace: workspaceId,
          }}
          onSubscriptionData={(arg: any) => onSubscriptionDataCallback(arg)}
        />
      )}
      <p
        data-cy='price-info'
        className={`text-13 ${
          isCreditLessThanPointFive ? 'text-red-500' : 'text-gray'
        } font-medium leading-4.5 flex gap-2`}
      >
        <Trans i18nKey='workspaceCurrentCredit' values={{ currentCredit: credit ?? '' }}>
          ${{ currentCredit: credit ?? '' }} USD Credit
        </Trans>
        {isCreditLessThanPointFive && <LowCreditButton>Low</LowCreditButton>}
      </p>
    </>
  );
};

export default CreditAmount;
