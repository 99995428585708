import { useContext, useEffect } from 'react';

import { useTwilioContext } from 'lib/twilio';
import { CAMPAIGN_STATUS_RESPONSE } from 'lib/twilio/constants';
import { activeCallCampaignStatus, isNetworkAvailable } from 'services/apollo/reactiveVars';
import { ENABLE_DIALER_V3 } from 'constants/featureFlags';
import { ToastMessage } from 'components/atoms';
import useSwitchV3Dialer from 'components/organisms/navigation/workspace-list-sidebar/useSwitchV3Dialer';
import DialerWidgetV3 from 'components/organisms/widgets-v3';
import DialerWidget from 'components/organisms/widgets/call-widget';
import { AuthContext } from 'contexts/auth/AuthContext';
import { OnSubscriptionDataOptions, useReactiveVar } from '@apollo/client';
import useUpdateCache from 'hooks/useUpdateCache';
import { UPDATE_CREDIT_SUBSCRIPTION } from 'graphql/sales-dialer/campaign';
import SubscriptionHandler from 'services/apollo/SubscriptionHandler';

import { useCampaignHandlers } from '../sales-dialer/campaigns/hooks/useCampaignHandlers';
import { CAMPAIGN_ACTIONS } from '../sales-dialer/campaigns/constants';
import useForceCampaignPause from './hooks/useForceCampaignPause';
import useUpdateCampaignCache from './hooks/useUpdateCampaignCache';

const DialerCallWidget = () => {
  const {
    state: { connection, showPhoneWidget, showDialer, salesDialerWidget },
  } = useTwilioContext();
  const { userAuthId } = useContext(AuthContext);

  const { forcePauseCampaign } = useForceCampaignPause();
  const { updateCampaignStatus } = useUpdateCampaignCache();

  // FETCH CHANNELS
  const { showToggleV3DialerOption, v3DialerVisible } = useSwitchV3Dialer();
  const { updateCreditCache } = useUpdateCache();
  const activeCampaignStatus = useReactiveVar<any>(activeCallCampaignStatus);
  const internetConnection = useReactiveVar(isNetworkAvailable);
  const { INPROGRESS } = CAMPAIGN_STATUS_RESPONSE;
  const campaignId = connection?.customParameters?.get('campaignId') || '';
  const conversationId = connection?.customParameters?.get('campaignConversationId') || '';
  const { handleCampaignRun } = useCampaignHandlers();

  const enableV3Dialer = showToggleV3DialerOption ? v3DialerVisible : ENABLE_DIALER_V3;

  useEffect(() => {
    if (internetConnection) {
      if (campaignId && activeCampaignStatus === INPROGRESS) {
        handleCampaignRun(campaignId, CAMPAIGN_ACTIONS.pause, conversationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetConnection]);

  // eslint-disable-next-line consistent-return
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (showPhoneWidget && !showDialer) {
      event.preventDefault();
      const message = 'A call is currently in progress.';
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
      return message;
    }
  };

  const handleUnload = async (event: Event) => {
    if (showPhoneWidget && salesDialerWidget && campaignId) {
      event.preventDefault();
      const payload = {
        id: campaignId,
        conversationId,
        action: CAMPAIGN_ACTIONS.pause,
      };
      await forcePauseCampaign(payload);
    }
  };

  const onSubscriptionDataCallback = (arg: OnSubscriptionDataOptions<any>) => {
    const { message } = arg?.subscriptionData?.data?.updateCredit || {};
    if (message) {
      const { creditAmount, isCallDisconnected, campaignStatus } = message || {};
      updateCreditCache(creditAmount);
      if (creditAmount < 1 && !isCallDisconnected && campaignStatus === INPROGRESS) {
        ToastMessage({
          content: 'Your credit is low! Please recharge soon to avoid service interruptions.',
          type: 'warning',
        });
        return;
      }
      if (isCallDisconnected) {
        ToastMessage({
          content: 'Call disconnected. Your credit is low!',
          type: 'danger',
        });
      }
      updateCampaignStatus(campaignStatus);
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPhoneWidget, showDialer, salesDialerWidget]);

  return (
    <>
      {showPhoneWidget && (
        <>
          {userAuthId && (
            <SubscriptionHandler
              subscription={UPDATE_CREDIT_SUBSCRIPTION}
              variables={{
                user: userAuthId,
              }}
              onSubscriptionData={(arg: any) => onSubscriptionDataCallback(arg)}
            />
          )}
          {enableV3Dialer ? <DialerWidgetV3 /> : <DialerWidget />}
        </>
      )}
    </>
  );
};
export default DialerCallWidget;
