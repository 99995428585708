import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Progress } from 'antd';
import { format } from 'date-fns';

import { useTwilioContext } from 'lib/twilio';
import { calculateProgressPercentage } from 'components/utils/helpers';
import * as path from 'constants/routes';
import { Button } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';

import { useFreeUsageBalanceQuery } from './hooks/useFreeUsageBalanceQuery';
import {
  UsageModalContent,
  UsageBalanceWrapper,
  StyledPercentTag,
  StyledModal,
  StyledPlanIntervalTag,
} from './Styles';

function isNumber(value: any) {
  return !Number.isNaN(value) && typeof value === 'number';
}

const WorkspaceUsageBalance = () => {
  const [isUsageModalOpen, setIsUsageModalOpen] = useState(false);
  const history = useHistory();
  const { loggedInMemberInfo } = useContext(AuthContext);
  const {
    freeUsageBalanceData,
    loadingFreeUsageBalance,
    fetchFreeUsageBalanceData,
  } = useFreeUsageBalanceQuery();

  const {
    state: { showPhoneWidget },
  } = useTwilioContext();

  useEffect(() => {
    fetchFreeUsageBalanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPhoneWidget]);

  const { planDetail, endedAt: planEndDate } = loggedInMemberInfo?.plan || {};
  const { interval, title: planTitle } = planDetail || {};

  const {
    inboundMinutes = 0,
    outboundMinutes = 0,
    smsSegments = 0,
    endedAt,
    totalInboundMinutes: TOTAL_INBOUND = 0,
    totalOutboundMinutes: TOTAL_OUTBOUND = 0,
    totalSmsSegments: TOTAL_SMS = 0,
  } = freeUsageBalanceData || {};

  const paymentDueDate = planEndDate ? new Date(planEndDate) : new Date();
  const formattedExpiryDate = format(paymentDueDate, 'MMMM dd, yyyy');
  const convertedRenewalDate = endedAt ? new Date(endedAt * 1000) : new Date();
  const formattedRenewalDate = format(convertedRenewalDate, 'MMMM dd, yyyy');

  const remainingInboundCallsPercentage = calculateProgressPercentage({
    part: inboundMinutes,
    total: TOTAL_INBOUND,
  });
  const remainingOutboundCallsPercentage = calculateProgressPercentage({
    part: outboundMinutes,
    total: TOTAL_OUTBOUND,
  });
  const remainingSMSPercentage = calculateProgressPercentage({
    part: smsSegments,
    total: TOTAL_SMS,
  });

  const NUMBER_OF_PROGRESS_BAR = 3;
  const circularProgressPercentage =
    (remainingInboundCallsPercentage + remainingOutboundCallsPercentage + remainingSMSPercentage) /
    NUMBER_OF_PROGRESS_BAR;

  const DANGER_LIMIT = 25;

  const progressItems = [
    {
      title: 'Inbound calls minutes',
      remaining: inboundMinutes,
      total: TOTAL_INBOUND,
      percent: remainingInboundCallsPercentage,
    },
    {
      title: 'Outbound calls minutes',
      remaining: outboundMinutes,
      total: TOTAL_OUTBOUND,
      percent: remainingOutboundCallsPercentage,
    },
    {
      title: 'SMS segments',
      remaining: smsSegments,
      total: TOTAL_SMS,
      percent: remainingSMSPercentage,
    },
  ];
  const durationElement = (
    <StyledPlanIntervalTag>{interval === 'year' ? 'Yearly' : 'Monthly'}</StyledPlanIntervalTag>
  );

  const openUsageModal = () => setIsUsageModalOpen(true);
  const closeUsageModal = () => setIsUsageModalOpen(false);

  // redirect to plan
  const viewPlan = () => {
    history.push(`${path.SETTINGS}${path.PLAN_SUBS}`);
    closeUsageModal();
  };

  return (
    <>
      <UsageBalanceWrapper onClick={openUsageModal}>
        <Progress
          width={18}
          type='circle'
          percent={loadingFreeUsageBalance ? 0 : circularProgressPercentage}
          format={() => ''} // this is requred to make middle section blank
          strokeWidth={20}
          strokeLinecap='butt'
          trailColor='#E7E6EB'
          status={circularProgressPercentage < DANGER_LIMIT ? 'exception' : 'success'}
          className='progress-circle-anti-clock'
        />
        <div className='usage-balance-text-wrapper'>
          <div className='flex gap-1.5 items-center'>
            <span>Usage Balance</span>
            <div className='px-1.5 h-4 bg-gray-200 text-gray-600 text-10 rounded font-medium leading-4'>
              Beta
            </div>
          </div>
          {!loadingFreeUsageBalance && (
            <StyledPercentTag>
              {isNumber(circularProgressPercentage) ? circularProgressPercentage.toFixed(2) : 0}%
            </StyledPercentTag>
          )}
        </div>
      </UsageBalanceWrapper>

      <StyledModal
        data-cy='workspace-usage-balance-modal'
        visible={isUsageModalOpen}
        className='modal'
        width={450}
        centered
        destroyOnClose
        footer={null}
        onCancel={closeUsageModal}
        closable={false}
      >
        <UsageModalContent>
          <div className='flex flex-col gap-10'>
            <div>
              <h5 className='text-center mb-2'>Usage Balance</h5>
              <p className='text-15 leading-5.75 text-gray text-center mx-auto'>
                Track your call and SMS balance usage.
              </p>
            </div>
            <div className='flex flex-col gap-8'>
              <header>
                <div className='flex flex-col gap-1'>
                  <div className='flex gap-1'>
                    <h3>{planTitle} Plan </h3>
                    {durationElement}
                  </div>
                  <p className='into-text'>Payment due on {formattedExpiryDate}</p>
                </div>
                <Button className='ml-auto' type='primary' onClick={viewPlan} variant='info'>
                  View Plan Details
                </Button>
              </header>
              <div className='flex flex-col gap-6 '>
                {progressItems?.map(item => (
                  <section key={item?.title}>
                    <h4>
                      <span>{item?.title}</span>
                      <span className='sub-text'>
                        {item?.remaining ?? 0} of {item?.total ?? 0} remaining
                      </span>
                    </h4>
                    <Progress
                      strokeWidth={5}
                      percent={item?.percent}
                      status={item?.percent < DANGER_LIMIT ? 'exception' : 'success'}
                      showInfo={false}
                    />
                  </section>
                ))}
              </div>
            </div>
            <p className='into-text'>
              Renewal date: {formattedRenewalDate}. Your complimentary call minutes and SMS segments
              are renewed monthly.
            </p>
          </div>
        </UsageModalContent>
      </StyledModal>
    </>
  );
};

export default WorkspaceUsageBalance;
