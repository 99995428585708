import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  .ant-btn {
    height: 32px;
    @media screen and (min-width: 769px) {
      height: 40px;
    }
  }
  &.fixed,
  &.absolute {
    min-height: 56px;
    width: calc(100% - 64px);
    & + div {
      margin-top: 75px;
    }
  }
  .header-title {
    padding-left: 2rem;
    @media screen and (min-width: 1024px) {
      padding-left: 1.7rem;
    }
    @media screen and (min-width: 1200px) {
      padding-left: 0;
    }
  }
  .has-nospacing {
    padding-left: 0;
  }
  .icon-arrow {
    path {
      fill: #564d6d;
    }

    @media screen and (max-width: 768px) {
      width: 15px;
    }
  }
  .tag-new {
    display: flex;
    padding: 2px 6px;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background-color: #1a63f4;
    color: white;
    width: 36px;
    height: 16px;
  }
`;
