/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo, useRef, useEffect, useContext } from 'react';
import { Skeleton } from 'antd';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { LIMIT } from 'hooks/useContactVars';
import uuid from 'components/utils/uuid';
import EmptyScreen from 'components/molecules/empty-screen';

import { Row } from './Row';
import { HandlersContext } from './HandlersContext';
import * as S from './Styles';

function ContactList() {
  const { contacts, hasNextPage, onLoadMore, loading, searchedQuery } = useContext<any>(
    HandlersContext,
  );
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, entry] = useIntersectionObserver({
    elementRef,
  });
  const targetElement = entry?.target;

  useEffect(() => {
    if (isVisible) {
      if (targetElement instanceof HTMLElement && hasNextPage) {
        targetElement?.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isVisible, targetElement]);

  return (
    <div>
      {loading && contacts.length < LIMIT ? (
        <div className='w-full'>
          <S.SkeletonContainer>
            {Array.from(Array(20), (item: number, index: number) => (
              <div key={uuid()} className='flex gap-3 px-3.5 py-2 ml-6 mr-3.5 '>
                <Skeleton.Avatar size={32} active />
                <Skeleton paragraph={{ rows: 1 }} active />
              </div>
            ))}
          </S.SkeletonContainer>
        </div>
      ) : (
        <S.ListWrapper>
          {searchedQuery && contacts.length === 0 ? (
            <div style={{ height: 'calc(100vh - 100px)' }}>
              <EmptyScreen contentTitle='No results found' />
            </div>
          ) : (
            contacts?.map((rowData: any, index: number) => {
              return <Row data={rowData?.node} index={index} key={rowData?.node?.id} />;
            })
          )}
        </S.ListWrapper>
      )}
      <div ref={elementRef} onClick={onLoadMore} className='h-4' />
    </div>
  );
}

export default memo(ContactList);
