import { createContext, useRef } from 'react';
import { OnSubscriptionDataOptions, useReactiveVar } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import SubscriptionHandler from 'services/apollo/SubscriptionHandler';
import { activeCallConversationData } from 'services/apollo/reactiveVars';
import { COMMUNICATION_SUBSCRIPTION } from 'graphql/channel/conversation';

import { useActiveCallWidgetHandler } from '../hooks/useActiveCallWidgetHandler';

export const ActiveCallWidgetContext = createContext({});

export const ActiveCallWidgetProvider = ({ children }: any) => {
  const conversationDataRef: any = useRef();
  const contextValue = useActiveCallWidgetHandler();
  const {
    state: { channelId },
  } = useTwilioContext();
  const conversationData = useReactiveVar(activeCallConversationData);

  conversationDataRef.current = conversationData;

  const onSubscriptionDataCallback = (arg: OnSubscriptionDataOptions<any>) => {
    const newMessage = arg?.subscriptionData?.data?.updateConversation?.message;
    const conversationStatus = newMessage?.conversationStatus;
    if (!newMessage) return;
    if (process.env.REACT_APP_DEBUG)
      console.log('[Subs updateConversation triggered call widget handler]:', newMessage);
    if (!conversationDataRef.current && newMessage?.conversationType === 'Call') {
      activeCallConversationData(newMessage);
    }
  };

  return (
    <ActiveCallWidgetContext.Provider value={contextValue}>
      {channelId && (
        <SubscriptionHandler
          subscription={COMMUNICATION_SUBSCRIPTION}
          variables={{
            channel: channelId,
          }}
          onSubscriptionData={(arg: any) => onSubscriptionDataCallback(arg)}
        />
      )}
      {children}
    </ActiveCallWidgetContext.Provider>
  );
};
